import React, {useEffect, useState} from "react"
import {Carousel, Col, Container, Image, Row} from "react-bootstrap";
import "./style.scss"

const SolutionsPlayer = ({ solutions }) => {
    const [activeSolutionSlides, setActiveSolutionSlides] = useState('Community');
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if(activeIndex === 1) {
                if(activeSolutionSlides === 'Community') {
                    setActiveIndex(activeIndex + 1);
                    return;
                }
                setActiveIndex(0)
                activeSolutionSlides === 'Education' && setActiveSolutionSlides(solutions[2].title)
                activeSolutionSlides === 'Mentoring' && setActiveSolutionSlides(solutions[0].title)
            }
            else if(activeSolutionSlides === 'Community' && activeIndex === 2) {
                setActiveIndex(0);
                setActiveSolutionSlides(solutions[1].title)
            }
            else setActiveIndex(activeIndex + 1);

        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [activeIndex, activeSolutionSlides, solutions]);

    const RenderImagesCarousel = (images, hasMarginLeft = false) => {
        return (
            <Carousel
                indicators
                className="mx-0"
                controls={false}
                activeIndex={activeIndex}
                style={{ width: '100%'}}
            >
                {Array.from(images).map(image => {
                    return  (
                        <Carousel.Item>
                            <Image style={{ width: '95%', marginLeft: hasMarginLeft ? '2em' : "unset"}} src={image}></Image>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }
    return (
        <Container fluid style={{ backgroundColor: '#F0F0F0', paddingBottom: '24em'}}>
            <Row className="d-none d-lg-block">
                <Col>
                    <Container>
                        <Row className="d-flex flex-row align-items-center justify-content-center">
                            <Col md={10}>
                                <Row className="d-flex flex-row flex-nowrap" style={{ marginBottom: '-5em'}}>
                                    {solutions.map(solution => {
                                        return (
                                            <Col
                                                style={{
                                                    background: activeSolutionSlides === solution.title ? solution.borderStyle : 'white',
                                                    borderRadius: '1em',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setActiveSolutionSlides(solution.title);
                                                    setActiveIndex(0)
                                                }}
                                                className={`mx-4 pb-0 px-1 ${activeSolutionSlides === solution.title ? 'pt-1' : 'pt-0'}`}
                                            >
                                                <div
                                                    className="bg-white d-flex flex-column align-items-center pb-4 px-4"
                                                    style={{ borderRadius: '1em', height: '98%', cursor: 'pointer'}}
                                                >
                                                    <Image style={{ width: '8em', marginTop: '-.1em', cursor: 'pointer'}} className="mb-4" src={solution.icon}/>
                                                    <p className="px-3 text-center">{solution.miniDescription}</p>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>
                        {solutions.map((solution, index) => {
                            return activeSolutionSlides === solution.title && (
                                <Row
                                    style={{
                                        background: solution.backgroundStyle,
                                        height: 'auto',
                                        padding: '10em 0em 4em 4em',
                                        marginRight: '0'
                                    }}
                                >
                                    <Col
                                        md={4}
                                        className="d-flex flex-column justify-content-center align-items-center rounded-circle"
                                        style={{ background: 'white', marginLeft: '-6em', padding: '2em', width: '26em', height: '26em'}}
                                    >
                                        <h1 className="fw-bolder">{solution.title}</h1>
                                        <p className="px-4">
                                            {solution.mainDescription}
                                        </p>
                                    </Col>
                                    <Col md={8} xs={12} style={{marginRight: '-6em', paddingBottom: '1em'}}>
                                        {RenderImagesCarousel(solution.slideImages, true)}
                                    </Col>
                                </Row>
                            )
                        })
                        }
                    </Container>
                </Col>
            </Row>
            <Row className="container-mobile">
                <Col>
                    <Container className="pb-5">
                        {solutions.map(solution => {
                            return  activeSolutionSlides === solution.title && (
                                <Row className="d-flex flex-row align-items-center justify-content-center" style={{  marginBottom: '-5em'}}>
                                    <Col>
                                        <Row className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
                                            <Col
                                                style={{
                                                    background: solution.borderStyle,
                                                    borderRadius: '1em'
                                                }}
                                                className="mx-4 pb-0 px-1 pt-1"
                                                sm={6}
                                                xs={9}
                                            >
                                                <div
                                                    className="bg-white d-flex flex-column align-items-center pb-4 px-4"
                                                    style={{ borderRadius: '1em', height: '98%'}}
                                                >
                                                    <Image style={{ width: '8em', marginTop: '-.1em'}} className="mb-4" src={solution.icon}/>
                                                    <p className="px-3 text-center">{solution.miniDescription}</p>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            )})}
                        {solutions.map(solution => {
                            return  activeSolutionSlides === solution.title && (
                                <Row
                                    style={{
                                        background: solution.backgroundStyle,
                                        height: 'auto',
                                        minHeight: '23em',
                                        padding: '6em 2em 2em',
                                        borderRadius: '1em'
                                    }}
                                >
                                    <Col>
                                        {RenderImagesCarousel(solution.slideImages)}
                                    </Col>
                                </Row>
                            )})}
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default SolutionsPlayer

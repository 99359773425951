import React from "react"
import { Container } from "react-bootstrap"
import GroupIllustration from "../images/new-hero-image.png"
import IncubatorEllipse from "../images/incubator-ellipse.svg"
import CommunityEllipse from "../images/community-ellipse.svg"
import AcceleratorEllipse from "../images/accelerator-ellipse.svg"
import VentureCapitalEllipse from "../images/venture-capital-ellipse.svg"
import PreviewHome from "../../static/images/preview-home 1.png"
import Connections from "../components/connections"
import Footer from "../components/footer"
import Layout from "../components/layout"
import LogoCloud from "../components/LogoClouds"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import NewHero from "../components/hero/newHero";
import communityIcon from "../images/community-icon.svg"
import educationIcon from "../images/education-icon.svg"
import mentoringIcon from "../images/mentor-icon.svg"
import communitySlide1 from "../images/community-slide-1.svg"
import communitySlide2 from "../images/community-slide-2.svg"
import communitySlide3 from "../images/community-slide-3.svg"
import educationSlide1 from "../images/education-slide-1.svg"
import educationSlide2 from "../images/education-slide-2.svg"
import mentoringSlide1 from "../images/meeting-slide-1.svg"
import mentoringSlide2 from "../images/meeting-slide-2.svg"
import SolutionsPlayer from "../components/solutions/Index";
import TeamCoachesFor from "./team-coaches-for";

const solutions = [
    {
        icon: communityIcon,
        title: 'Community',
        miniDescription: 'Keep your community engage',
        mainDescription: 'Engage, share, and meet with your community with the community that you were looking for using announcements, events, newsletters, resources, and many more.',
        slideImages: [communitySlide1, communitySlide2, communitySlide3],
        backgroundStyle: 'linear-gradient(180deg, rgba(82, 163, 233, 0.8) 0%, rgba(82, 163, 233, 0.48) 100%)',
        borderStyle: 'linear-gradient(180deg, #1F7ABF 49.75%, #FFFFFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)'
    },
    {
        icon: educationIcon,
        title: 'Education',
        miniDescription: 'Educate your community with the latest trends',
        mainDescription: <span>Build courses for your community, tracking progress, creating actions and assignments. <br /><br /> A full LMS for you to create the next level courses for your users</span>,
        slideImages: [educationSlide1, educationSlide2],
        backgroundStyle: 'linear-gradient(180deg, rgba(253, 181, 35, 0.8) 0%, rgba(253, 181, 35, 0.48) 100%)',
        borderStyle: 'linear-gradient(180deg, #FDB523 49.95%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)'
    },
    {
        icon: mentoringIcon,
        title: 'Mentoring',
        miniDescription: 'Guide and strengthen skills with mentors and coaches ',
        mainDescription: 'Meet and track improvements using the latest and robust mentoring solution, booking meeting with mentors and coaches, tracking progress and creating notes.',
        slideImages: [mentoringSlide1, mentoringSlide2],
        backgroundStyle: 'linear-gradient(180deg, rgba(229, 37, 42, 0.8) 0%, rgba(229, 37, 42, 0.48) 100%)',
        borderStyle: 'linear-gradient(180deg, #E5252A 49.95%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)'
}];
const teamCoachesFor = [
    [
        {
            title: "Community",
            body: "Keep your community engage and up today's news and events",
            ellipseImage: CommunityEllipse,
            linkTo: "/product",
            color: "#1F7ABF",
            spots: [
                {
                    styles: {
                        background: '#FDB523',
                        width: '2.5em',
                        height: '2.5em',
                        position: 'absolute',
                        top: '1em',
                        left: '3em'
                    }
                },
                {
                    styles: {
                        background: '#1F7ABF',
                        width: '1.5em',
                        height: '1.5em',
                        position: 'absolute',
                        bottom: '8em',
                        left: '0em'
                    }
                }
            ]
        },
        {
            title: "Incubator",
            body: "Transform business ideas from clients into a reality",
            ellipseImage: IncubatorEllipse,
            linkTo: "/product",
            color: "#E5252A",
            spots: [
                {
                    styles: {
                        background: '#FDB523',
                        width: '2.5em',
                        height: '2.5em',
                        position: 'absolute',
                        bottom: '7em',
                        right: '0'
                    }
                },
                {
                    styles: {
                        background: '#E5252A',
                        width: '1.5em',
                        height: '1.5em',
                        position: 'absolute',
                        bottom: '3em',
                        left: '3em'
                    }
                }
            ]
        }
    ],
    [
        {
            title: "Accelerator",
            body: "Take your clients projects to the next level with the best result",
            ellipseImage: AcceleratorEllipse,
            linkTo: "/product",
            color: "#FDB523",
            spots: [
                {
                    styles: {
                        background: '#FDB523',
                        width: '2.5em',
                        height: '2.5em',
                        position: 'absolute',
                        top: '10em',
                        left: '0'
                    }
                },
                {
                    styles: {
                        background: '#E5252A',
                        width: '1.5em',
                        height: '1.5em',
                        position: 'absolute',
                        bottom: '1em',
                        left: '7em'
                    }
                }
            ]
        },
        {
            title: "Venture Capital",
            body: "Support your investment through real-time metrics and data",
            ellipseImage: VentureCapitalEllipse,
            linkTo: "/product",
            color: "#1F7ABF",
            spots: [
                {
                    styles: {
                        background: '#1F7ABF',
                        width: '2.5em',
                        height: '2.5em',
                        position: 'absolute',
                        bottom: '10em',
                        right: '0'
                    }
                },
                {
                    styles: {
                        background: '#FFC54F',
                        width: '1.5em',
                        height: '1.5em',
                        position: 'absolute',
                        top: '2em',
                        left: '3em'
                    }
                }
            ]
        }
    ]
]

const IndexPage = ({ location }) => {

    return (
        <Layout>
            <Seo title="Team Coaches" />
            <NavBar location={location} />
            <Container fluid className="px-0">
                <NewHero
                    title="The Platform for  entrepreneurship  communities"
                    description="Team Coaches is a community software that empower your community through tools dedicated to innovation."
                    image={GroupIllustration}
                    altText="Group conversation"
                    isHome
                />
                <Container>
                    <LogoCloud
                        isHome
                        title={<>“Happy <span className="h4" style={{ color: '#0066CC'}}>Communities</span> using our <span className="h4"  style={{ color: '#0066CC'}}>Platform</span>”</>}
                        titleStyles={{
                            background: "white",
                            lineHeight: "1.6em",
                        }}
                    />
                </Container>
                <Connections
                    isHome
                    title="Save Time, Increase Growth, Build A Thriving Community"
                    description="Build, customize, and scale your innovation programs with the perfect set of tools. Accelerate your startup management, e-learning, mentoring/coaching, and administrative tasks."
                    imageSource={PreviewHome}
                />
                <TeamCoachesFor teamCoachesFor={teamCoachesFor} />
                <SolutionsPlayer solutions={solutions} />
                <Footer newFooter />
            </Container>
        </Layout>
    )
}

export default IndexPage
